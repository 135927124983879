import * as React from "react";
import {ChakraProvider} from "@chakra-ui/react"
import {
    MemoryRouter,
    Route,
    Routes
} from "react-router-dom";
import {IntlProvider} from "react-intl";
import moment from 'moment';
import 'moment/locale/de';
import German from './lang/de.json';

import GummiesTemplate from "./templates/GummiesTemplate";
import ChakraTheme from "./templates/Chakra";
import Home from "./pages/home/Home";
import Step2 from "./pages/step2/Step2";
import Step1 from "./pages/step1/Step1";
import Loader from "./pages/loader/Loader";
import Complete from "./pages/complete/Complete";

function App() {
    let locale = 'de';
    let momentLocale = 'de';
    let messages = German;

    /*
    switch (true) {
        case (currentUrl.search('/uk') !== -1):
            locale = 'en';
            momentLocale = 'en-gb';
            messages = English;
            messages.outgoingUrl = 'https://sgummies-shop.com/uk/shop-now.html';
            break;

        case (currentUrl.search('/ie') !== -1):
            locale = 'en';
            momentLocale = 'en-gb';
            messages = English;
            messages.outgoingUrl = 'https://sgummies-shop.com/ie/shop-now.html';
            break;

        case (currentUrl.search('/ch') !== -1):
            locale = 'ch';
            messages.outgoingUrl = 'https://sgummies-shop.com/ch/jetzt-kaufen.html';
            break;

        case (currentUrl.search('/at') !== -1):
            locale = 'at';
            messages.outgoingUrl = 'https://s-gummies.com/at/jetzt-kaufen.html';
            break;

        case (currentUrl.search('/fr') !== -1):
            locale = 'fr';
            momentLocale = 'fr';
            messages = French;
            break;

        case (currentUrl.search('/nl') !== -1):
            locale = 'nl';
            momentLocale = 'nl';
            messages = Dutch;
            break;

        case (currentUrl.search('/no') !== -1):
            locale = 'no';
            momentLocale = 'nb';
            messages = Norwegian;
            break;

        case (currentUrl.search('/it') !== -1):
            locale = 'it';
            momentLocale = 'it';
            messages = Italian;
            break;

        case (currentUrl.search('/es') !== -1):
            locale = 'es';
            momentLocale = 'es';
            messages = Spanish;
            break;

        case (currentUrl.search('/pl') !== -1):
            locale = 'pl';
            momentLocale = 'pl';
            messages = Polish;
            break;

        case (currentUrl.search('/fi') !== -1):
            locale = 'fi';
            momentLocale = 'fi';
            messages = Finish;
            break;

        case (currentUrl.search('/dk') !== -1):
            locale = 'dk';
            momentLocale = 'da';
            messages = Danish;
            break;

        case (currentUrl.search('/benl') !== -1):
            locale = 'nl';
            momentLocale = 'nl';
            messages = Dutch;
            messages.outgoingUrl = 'https://s-gummies.com/benl/nu-kopen.html';
            break;

        case (currentUrl.search('/befr') !== -1):
            locale = 'fr';
            momentLocale = 'fr';
            messages = French;
            messages.outgoingUrl = 'https://s-gummies.com/befr/acheter-maintenant.html';
            break;

        case (currentUrl.search('/lude') !== -1):
            messages.outgoingUrl = 'https://s-gummies.com/lude/jetzt-kaufen.html';
            break;

        case (currentUrl.search('/lufr') !== -1):
            locale = 'fr';
            momentLocale = 'fr';
            messages = French;
            messages.outgoingUrl = 'https://s-gummies.com/lufr/acheter-maintenant.html';
            break;
    }
     */

    document.title = 'Slimming Gummies - ' + messages.title;

    moment.locale(momentLocale);

    const config = {
        locale: locale
    }

    return (
        <MemoryRouter>
            <ChakraProvider theme={ChakraTheme}>
                <IntlProvider locale={locale} messages={messages} defaultLocale="de">
                    <GummiesTemplate>
                        <Routes>
                            <Route path='/' element={
                                <Home/>
                            }/>
                            <Route path='/step1' element={
                                <Step1/>
                            }/>
                            <Route path='/step2' element={
                                <Step2 config={config}/>
                            }/>
                            <Route path='/loader' element={
                                <Loader/>
                            }/>
                            <Route path='/complete' element={
                                <Complete config={config}/>
                            }/>
                        </Routes>
                    </GummiesTemplate>
                </IntlProvider>
            </ChakraProvider>
        </MemoryRouter>
    );
}

export default App;
