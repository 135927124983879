import './Complete.scss';
import {Box, Container, Grid, GridItem, Heading, Text, Flex} from "@chakra-ui/react";
import useStore from "../../storage/DataStore";
import BodyImage from "../../components/body-selector/BodyImage";
import React from "react";
import moment from "moment/moment";
import {FormattedMessage, useIntl} from "react-intl";
import Products from "../../components/products/Products";
import {Reviews} from "../../components/reviews/Reviews";

function Complete(props) {
    const intl = useIntl();
    let outgoingLink = intl.formatMessage({id: "outgoingUrl"});

    if (window.location.search !== '') {
        outgoingLink += window.location.search;
    }

    const state = useStore.getState();
    const fitnessCalculator = require('fitness-health-calculations');

    let weight = state.weight;
    let targetWeight = state.targetWeight;
    const weightDifference = weight - targetWeight;
    let caloriesDaily = 7000;

    let unit = 'kg';

    if (props.config.locale === 'en') {
        unit = 'st';
        caloriesDaily = 10000;
    }

    let tDee = fitnessCalculator.tdee(
        'male',
        18,
        180,
        parseInt(weight),
        'moderate'
    );

    let trackingIframe = null;

    const urlParams = new URLSearchParams(window.location.search);
    const currentUrl = window.location.toString();

    const ts = Math.floor(Date.now() / 1000);

    if (currentUrl.search('/de') !== -1 && urlParams.has('affiliate') && urlParams.get('affiliate') === '25' && urlParams.has('subid2')) {
        trackingIframe = 'https://glossy-transfer.com/p.ashx?o=17270&e=5604&t=' + ts + '&r=' + urlParams.get('subid2');
    }
    if (currentUrl.search('/at') !== -1 && urlParams.has('affiliate') && urlParams.get('affiliate') === '25' && urlParams.has('subid2')) {
        trackingIframe = 'https://glossy-transfer.com/p.ashx?o=17271&e=5605&t=' + ts + '&r=' + urlParams.get('subid2');
    }
    if (currentUrl.search('/ch') !== -1 && urlParams.has('affiliate') && urlParams.get('affiliate') === '25' && urlParams.has('subid2')) {
        trackingIframe = 'https://glossy-transfer.com/p.ashx?o=17272&e=5606&t=' + ts + '&r=' + urlParams.get('subid2');
    }
    if (currentUrl.search('/fr') !== -1 && urlParams.has('affiliate') && urlParams.get('affiliate') === '25' && urlParams.has('subid2')) {
        trackingIframe = 'https://glossy-transfer.com/p.ashx?o=17273&e=5607&t=' + ts + '&r=' + urlParams.get('subid2');
    }
    if (currentUrl.search('/nl') !== -1 && urlParams.has('affiliate') && urlParams.get('affiliate') === '25' && urlParams.has('subid2')) {
        trackingIframe = 'https://glossy-transfer.com/p.ashx?o=17394&e=5629&t=' + ts + '&r=' + urlParams.get('subid2');
    }
    if (currentUrl.search('/uk') !== -1 && urlParams.has('affiliate') && urlParams.get('affiliate') === '25' && urlParams.has('subid2')) {
        trackingIframe = 'https://glossy-transfer.com/p.ashx?o=17274&e=5608&t=' + ts + '&r=' + urlParams.get('subid2');
    }
    if (currentUrl.search('/ie') !== -1 && urlParams.has('affiliate') && urlParams.get('affiliate') === '25' && urlParams.has('subid2')) {
        trackingIframe = 'https://glossy-transfer.com/p.ashx?o=17351&e=5641&t=' + ts + '&r=' + urlParams.get('subid2');
    }
    if (currentUrl.search('/it') !== -1 && urlParams.has('affiliate') && urlParams.get('affiliate') === '25' && urlParams.has('subid2')) {
        trackingIframe = 'https://glossy-transfer.com/p.ashx?o=17504&e=5659&t=' + ts + '&r=' + urlParams.get('subid2');
    }
    if (currentUrl.search('/dk') !== -1 && urlParams.has('affiliate') && urlParams.get('affiliate') === '25' && urlParams.has('subid2')) {
        trackingIframe = 'https://glossy-transfer.com/p.ashx?o=17503&e=5658&t=' + ts + '&r=' + urlParams.get('subid2');
    }
    if (currentUrl.search('/pl') !== -1 && urlParams.has('affiliate') && urlParams.get('affiliate') === '25' && urlParams.has('subid2')) {
        trackingIframe = 'https://glossy-transfer.com/p.ashx?o=17502&e=5657&t=' + ts + '&r=' + urlParams.get('subid2');
    }
    if (currentUrl.search('/fi') !== -1 && urlParams.has('affiliate') && urlParams.get('affiliate') === '25' && urlParams.has('subid2')) {
        trackingIframe = 'https://glossy-transfer.com/p.ashx?o=17520&e=5665&t=' + ts + '&r=' + urlParams.get('subid2');
    }
    if (currentUrl.search('/es') !== -1 && urlParams.has('affiliate') && urlParams.get('affiliate') === '25' && urlParams.has('subid2')) {
        trackingIframe = 'https://glossy-transfer.com/p.ashx?o=17531&e=5669&t=' + ts + '&r=' + urlParams.get('subid2');
    }
    if (currentUrl.search('/lude') !== -1 && urlParams.has('affiliate') && urlParams.get('affiliate') === '25' && urlParams.has('subid2')) {
        trackingIframe = 'https://glossy-transfer.com/p.ashx?o=17558&e=5682&t=' + ts + '&r=' + urlParams.get('subid2');
    }
    if (currentUrl.search('/lufr') !== -1 && urlParams.has('affiliate') && urlParams.get('affiliate') === '25' && urlParams.has('subid2')) {
        trackingIframe = 'https://glossy-transfer.com/p.ashx?o=17559&e=5683&t=' + ts + '&r=' + urlParams.get('subid2');
    }
    if (currentUrl.search('/no') !== -1 && urlParams.has('affiliate') && urlParams.get('affiliate') === '25' && urlParams.has('subid2')) {
        trackingIframe = 'https://glossy-transfer.com/p.ashx?o=17478&e=5689&t=' + ts + '&r=' + urlParams.get('subid2');
    }
    if (currentUrl.search('/befr') !== -1 && urlParams.has('affiliate') && urlParams.get('affiliate') === '25' && urlParams.has('subid2')) {
        trackingIframe = 'https://glossy-transfer.com/p.ashx?o=17454&e=5691&t=' + ts + '&r=' + urlParams.get('subid2');
    }
    if (currentUrl.search('/benl') !== -1 && urlParams.has('affiliate') && urlParams.get('affiliate') === '25' && urlParams.has('subid2')) {
        trackingIframe = 'https://glossy-transfer.com/p.ashx?o=17451&e=5690&t=' + ts + '&r=' + urlParams.get('subid2');
    }

    let estimatedDays = Math.round(weightDifference * caloriesDaily / tDee);
    let estimatedEndDate = moment().add(estimatedDays, 'days');
    let startDate = moment(estimatedEndDate).subtract(7, 'days');

    const currentDate = moment().format('Do MMMM YYYY');

    const estimatedStartDate = startDate.format('Do MMMM YYYY');
    const estimatedEndDateFormatted = estimatedEndDate.format('Do MMMM YYYY');

    return (
        <Container maxW="container.md" p={1}>
            <Box
                background={"white"}
                borderWidth="1px"
                borderRadius="lg"
                p={[1]}
                textAlign={"center"}
            >
                <Heading p={3} pl={[1, 6]} pr={[1, 6]} fontSize={{base: "16px", lg: "28px"}} textAlign={'center'}
                         lineHeight={[1.6, 1.8]}>
                    <FormattedMessage id="complete.headline.paragraph.1"/>
                    {targetWeight}
                    <FormattedMessage id="complete.headline.paragraph.2"/>
                    {estimatedDays}
                    <FormattedMessage id="complete.headline.paragraph.3"/>
                    <span className="text-gradient">{estimatedStartDate}</span>
                    <FormattedMessage id="complete.headline.paragraph.4"/>
                    <span className="text-gradient">{estimatedEndDateFormatted}</span>
                    <FormattedMessage id="complete.headline.paragraph.5"/>
                </Heading>

                <Grid templateColumns='repeat(2, 1fr)' gap={1} className={'top-bar'} textAlign={'center'}>
                    <GridItem w='100%'>
                        <BodyImage gender={state.gender} bodyType={state.bodyType}/>
                        <Box background={'#e5e4e0'} p={2}>
                            <Text fontWeight={'bold'}
                                  fontSize={'lg'}>
                                <FormattedMessage id="complete.bodyFat.now"/>
                            </Text>
                        </Box>

                        <Box p={3} fontWeight={'bold'}>
                            <Text><FormattedMessage id="complete.bodyFat"/></Text>
                            <Text fontSize={'xl'} color={'brand'}>
                                {state.bodyType === 1 && <Text>15-17%</Text>}
                                {state.bodyType === 2 && <Text>17-25%</Text>}
                                {state.bodyType === 3 && <Text>25-30%</Text>}
                                {state.bodyType === 4 && <Text>30-35%</Text>}
                                {state.bodyType === 5 && <Text>35-40%</Text>}
                            </Text>
                        </Box>
                    </GridItem>
                    <GridItem w='100%'>
                        <BodyImage gender={state.gender} bodyType={1}/>
                        <Box background={'#e5e4e0'} p={2}>
                            <Text fontWeight={700}
                                  fontSize={'lg'}>
                                <FormattedMessage id="complete.bodyFat.estimated" values={{days: estimatedDays}}/>
                            </Text>
                        </Box>
                        <Box p={3} fontWeight={700}>
                            <Text><FormattedMessage id="complete.bodyFat"/></Text>
                            <Text fontSize={'xl'} color={'brand'}>
                                15-17%
                            </Text>
                        </Box>
                    </GridItem>
                </Grid>

                <Flex className="graph-container">
                    <Box w="100%">
                        <Box className="estimated_dates_wrap">
                            <Box className="current_date">
                                <span>{currentDate}</span>
                            </Box>

                            <Box className="goal_dates">
                                <span>{estimatedEndDateFormatted}</span>
                            </Box>
                        </Box>
                        <svg xmlns="http://www.w3.org/2000/svg"
                             className="svg-graph" viewBox="25 18 515 272" width="515" height="272">
                            <linearGradient id="a" x1="0%" y1="0%" x2="0%" y2="100%">
                                <stop offset="0" stopColor="#e74b55" stopOpacity="0.08"></stop>
                                <stop offset="0.25" stopColor="#e74b55" stopOpacity="0.1"></stop>
                                <stop offset="0.75" stopColor="#e74b55" stopOpacity="0.35"></stop>
                                <stop offset="1" stopColor="#e74b55" stopOpacity="0.5"></stop>
                            </linearGradient>

                            <linearGradient id="b" gradientUnits="userSpaceOnUse" x1="-325.711" x2="406.582"
                                            y1="635.122" y2="635.122">
                                <stop offset="0" stopColor="#e74b55"></stop>
                                <stop offset="1" stopColor="#e74b55"></stop>
                            </linearGradient>

                            <path className="verticalYJMM"
                                  d="m 499.16826,18.287226 0,215.299534 M 71.824417,50.707103 l 0,-32.419877"
                                  stroke="#cccccc" strokeLinecap="round" strokeWidth="2"></path>

                            <g strokeWidth="1.419" transform="matrix(.704 0 0 .704 254.421 -501.894)">
                                <g fillRule="evenodd">
                                    <circle cx="-260" cy="790" fill="#e74b55" r="18">
                                    </circle>
                                    <circle cx="347.52" cy="1046.5" fill="#e74b55" r="18"></circle>
                                </g>

                                <g className="lavelSVG">
                                    <text x="-259.728" y="830.083" className="svgText cSad">
                                        <tspan fill="#0090d4"></tspan>
                                        {weight} {unit}
                                    </text>
                                    <text x="347.80" y="1086.85" className="svgText cGreen">
                                        <tspan fill="#0090d4"></tspan>
                                        {targetWeight} {unit}
                                    </text>
                                </g>

                                <text transform="rotate(13.553431)" className="svgText" x="260.59555" y="887.11548">
                                    <tspan>{estimatedDays} <FormattedMessage id="complete.days"/></tspan>
                                </text>

                                <path
                                    d="M 22.840437,932.52897 C -97.103516,909.7525 -181.16095,828.77495 -259.77236,792.93941 c -21.82519,-9.94916 -43.23059,-6.70573 -64.86765,-7.48419 l -1.24209,339.39418 731.53409,0 -1.23199,-72.0852 c -20.15615,-0.1448 -39.05105,0.072 -57.03792,-3.0413 C 232.79247,1029.8902 155.05635,957.63579 22.840437,932.52897 Z"
                                    fill="url(#a)" fillRule="evenodd"></path>

                                <path
                                    d="m 404.42,1051.282 c -20.4096,-0.1466 -39.52603,0.026 -57.716,-3.1594 C 232.4854,1028.123 154.79539,956.10408 22.840437,931.04681 -97.374807,908.21883 -181.54101,826.92618 -260.30568,791.21476 c -21.64064,-9.81173 -42.85471,-6.57746 -64.33432,-7.35027"
                                    fill="none" stroke="url(#b)" strokeDasharray="4 8"
                                    strokeLinecap="round" strokeWidth="3">
                                    <animate attributeName="stroke-dashoffset" attributeType="XML"
                                             values="0;12" dur="0.48s" repeatCount="indefinite"
                                             repeatDur="indefinite" end="" fill="freeze" calcMode="linear"
                                             restart="whenNotActive"></animate>
                                </path>
                            </g>
                            <path className="face_1"
                                  d="m 66.100964,58.094734 h 10.560081 c 0.689772,0 1.245075,0.555304 1.245075,1.245075 0,0.689772 -0.555303,1.245076 -1.245075,1.245076 H 66.100964 c -0.689772,0 -1.245076,-0.555304 -1.245076,-1.245076 0,-0.689771 0.555304,-1.245075 1.245076,-1.245075 z m 5.281008,-19.245731 c -8.50269,0 -15.41796,6.91333 -15.41796,15.41602 0,8.50268 6.91527,15.417962 15.41796,15.417962 8.50269,0 15.41602,-6.915282 15.41602,-15.417962 0,-8.50269 -6.91333,-15.41602 -15.41602,-15.41602 z m 0,2 c 7.4218,0 13.41602,5.99421 13.41602,13.41602 0,7.4218 -5.99422,13.41796 -13.41602,13.41796 -7.4218,0 -13.41796,-5.99616 -13.41796,-13.41796 0,-7.42181 5.99616,-13.41602 13.41796,-13.41602 z m -5.0371,7.68165 c -1.327607,6e-4 -2.403699,1.076683 -2.40431,2.40429 -4.71e-4,1.328373 1.075937,2.405649 2.40431,2.40625 1.329126,4.66e-4 2.406711,-1.077124 2.40624,-2.40625 -6.12e-4,-1.328361 -1.077879,-2.404755 -2.40624,-2.40429 z m 10.07812,0 c -1.327607,6e-4 -2.403699,1.076683 -2.40431,2.40429 -4.71e-4,1.328373 1.075937,2.405649 2.40431,2.40625 1.329892,0.0015 2.408672,-1.076357 2.4082,-2.40625 -6.11e-4,-1.329127 -1.079074,-2.405839 -2.4082,-2.40429 z"
                                  fill="#fff"></path>
                            <path className="face_2"
                                  d="m 499.07604,219.42504 c -8.50269,0 -15.41796,6.91532 -15.41796,15.41802 0,8.50263 6.91527,15.41598 15.41796,15.41598 8.5027,0 15.41602,-6.91335 15.41602,-15.41598 0,-8.5027 -6.91332,-15.41802 -15.41602,-15.41802 z m 0,1.99999 c 7.4218,0 13.41797,5.99618 13.41797,13.41803 0,7.42178 -5.99617,13.41599 -13.41797,13.41599 -7.42181,0 -13.41796,-5.99421 -13.41796,-13.41599 0,-7.42185 5.99615,-13.41803 13.41796,-13.41803 z m -5.03515,7.68163 a 2.4053976,2.4053976 0 0 0 -2.40625,2.4043 2.4053976,2.4053976 0 0 0 2.40625,2.4062 2.4053976,2.4053976 0 0 0 2.40429,-2.4062 2.4053976,2.4053976 0 0 0 -2.40429,-2.4043 z m 10.07812,0 a 2.4053976,2.4053976 0 0 0 -2.40625,2.4043 2.4053976,2.4053976 0 0 0 2.40625,2.4062 2.4053976,2.4053976 0 0 0 2.40625,-2.4062 2.4053976,2.4053976 0 0 0 -2.40625,-2.4043 z m -10.79492,9.15432 a 1.1124706,1.1124706 0 0 0 -0.8125,1.84758 c 1.60807,1.88623 4.02679,2.96124 6.57422,2.96095 a 1.1124706,1.1124706 0 0 0 0.002,0 c 2.54335,0 4.9554,-1.07824 6.56055,-2.96095 a 1.1125917,1.1125917 0 0 0 -1.69141,-1.44531 c -1.15889,1.35935 -2.95737,2.17923 -4.87109,2.18169 -1.91812,1.5e-4 -3.71969,-0.81966 -4.88086,-2.18169 a 1.1124706,1.1124706 0 0 0 -0.88084,-0.40234 z"
                                  fill="#fff"></path>
                        </svg>
                    </Box>
                </Flex>
            </Box>

            <Heading size="lg" textAlign={'center'} mt={5} mb={5}>
                <FormattedMessage id="complete.productRecommendation.headline"/>
            </Heading>

            <Box bg={'white'}
                 p={3}
                 borderWidth="3px"
                 borderColor="brand"
                 borderRadius="lg">
                <Products locale={props.config.locale} totalDays={estimatedDays}/>
            </Box>

            <Box textAlign={'center'} mt={5}>
                <a href={outgoingLink} className="primary-btn pulse">
                    <FormattedMessage id="complete.button.outgoingLink"/>
                </a>
            </Box>

            <Box bg={'white'}
                 p={5}
                 borderWidth="1px"
                 borderRadius="lg">
                <Reviews locale={props.config.locale}/>
            </Box>

            <Box textAlign={'center'} mt={5}>
                <a href={outgoingLink} className="primary-btn pulse">
                    <FormattedMessage id="complete.button.outgoingLink"/>
                </a>
            </Box>

            {trackingIframe &&
                <iframe src={trackingIframe} title={'tkr'} height="1" width="1" frameBorder="0"></iframe>
            }
        </Container>
    );
}

export default Complete;
